/** @jsx jsx */
import { Flex, jsx } from 'theme-ui'

const NavMenuControls = ({ children }) => {
  return (
    <Flex sx={{ flexShrink: 0, alignItems: 'center' }}>
      {children}
    </Flex>
  )
}

export default NavMenuControls
