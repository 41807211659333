import { graphql, useStaticQuery } from 'gatsby'

const useNavigationQuery = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulNavigationBar(slug: { eq: "navigation-bar" }) {
        pages {
          id
          slug
          name
        }
      }
    }
  `)

  return data.contentfulNavigationBar
}

export default useNavigationQuery
