import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadataQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            social {
              facebook
              instagram
              pinterest
            }
          }
        }
        metadata: contentfulSiteMetadata(slug: { eq: "site-metadata" }) {
          default: defaultPageMetadata {
            description {
              description
            }
            image {
              file {
                url
              }
            }
            path
            title
          }
          pages: pageMetadata {
            description {
              description
            }
            image {
              file {
                url
              }
            }
            path
            title
          }
        }
      }
    `
  )

  return data
}

export default useSiteMetadataQuery
