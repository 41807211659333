import { graphql, useStaticQuery } from 'gatsby'

const useFooterQuery = () => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery($locale: String) {
        catalog: contentfulCatalog(
          slug: { eq: "catalog" }
          node_locale: { eq: $locale }
        ) {
          collections {
            title
            slug
          }
        }
        links: contentfulNavigationBar(
          slug: { eq: "footer-links" }
          node_locale: { eq: $locale }
        ) {
          pages {
            slug
            name
          }
        }
        legal: contentfulNavigationBar(
          slug: { eq: "footer-legal" }
          node_locale: { eq: $locale }
        ) {
          pages {
            slug
            name
          }
        }
      }
    `
  )

  return data
}

export default useFooterQuery
