import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import NavBar from '~/components/Nav/Bar'
import useNavigationQuery from '~/hooks/graphql/queries/use-navigation'

export const Nav = props => {
  const lineItems = useSelector(
    state => state.cart.data.lineItems,
    shallowEqual
  )

  const itemCount =
    lineItems && lineItems.reduce((acc, item) => acc + item.quantity, 0)

  const { pages } = useNavigationQuery()

  return <NavBar itemCount={itemCount} pages={pages} {...props} />
}

export default Nav
